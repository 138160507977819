import { css } from '@emotion/react'
import AvenirStd from '../assets/fonts/AvenirLTStd-Roman.otf'
import AvenirLight from '../assets/fonts/AvenirLTStd-Light.otf'
import AvenirBlack from '../assets/fonts/AvenirLTStd-Black.otf'
import AvenirBook from '../assets/fonts/AvenirLTStd-Book.otf'
import AvenirRoman from '../assets/fonts/AvenirLTStd-Roman.otf'
import AvenirHeavy from '../assets/fonts/AvenirLTStd-Heavy.otf'
import AvenirMedium from '../assets/fonts/AvenirLTStd-Medium.otf'
import AvenirOblique from '../assets/fonts/AvenirLTStd-Oblique.otf'
import { adminPortalColours } from './colours'

export const globalStyles = css`
  @font-face {
    font-family: 'AvenirStd';
    src: url(${AvenirStd}) format('opentype');
  }
  @font-face {
    font-family: 'AvenirLight';
    src: url(${AvenirLight}) format('opentype');
  }
  @font-face {
    font-family: 'AvenirBlack';
    src: url(${AvenirBlack}) format('opentype');
  }
  @font-face {
    font-family: 'AvenirBook';
    src: url(${AvenirBook}) format('opentype');
  }
  @font-face {
    font-family: 'AvenirRoman';
    src: url(${AvenirRoman}) format('opentype');
  }
  @font-face {
    font-family: 'AvenirHeavy';
    src: url(${AvenirHeavy}) format('opentype');
  }
  @font-face {
    font-family: 'AvenirMedium';
    src: url(${AvenirMedium}) format('opentype');
  }
  @font-face {
    font-family: 'AvenirOblique';
    src: url(${AvenirOblique}) format('opentype');
  }

  html,
  body {
    margin: 0;
    height: 100%;
    font-family: 'AvenirStd';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  #root {
    height: 100%;
  }
  
  *:focus-visible {
    outline-style: solid;
    outline-offset: -1px;
    outline-width: 1px;
  }

  p {
    margin: 0;
  }

  a {
    font-size: 16px;
    color: ${adminPortalColours.greys.label};
    height: 1em;
  }

  h1,
  h2,
  h3,
  h4 {
    font-family: 'AvenirBlack';
    margin: 0;
    height: 1em;
  }

  h1 {
    font-size: 22px;
  }

  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 16px;
  }

  h4 {
    font-size: 14px;
  }

  p {
    font-size: 16px;
  }

  p.min {
    font-size: 14px;
  }

  label {
    font-family: 'AvenirStd';
    font-size: 14px;
  }

  button {
    font-family: 'AvenirBlack';
    text-transform: uppercase;
  }

  button {
    font-size: 12px;
  }

  button.min {
    font-size: 10px;
  }

  input[type='radio'] {
    border: 2px solid ${adminPortalColours.greys.label};
    border-radius: 50px;
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    margin: 0;
    margin-right: 10px;
    cursor: pointer;
  }

  input[type='radio']:checked {
    background-color: ${adminPortalColours.white};
    border: 6px solid ${adminPortalColours.primary};
  }

  input[type='text']:focus,
  input[type='button']:focus {
    outline: 0 !important;
    outline-offset: 0 !important;
  }

  table {
    border-spacing: 0;
    width: 100%;
    margin: 0 auto;

    tr {
      line-height: 45px;
    }

    .clickable {
      :hover td {
        background-color: ${adminPortalColours.greys.bgHover};
        cursor: pointer;
      }

      .tableRowChevron svg {
        fill: ${adminPortalColours.greys.label};
        position: relative;
        left: 0px;
        transition: left 0.07s;
      }

      :hover .tableRowChevron svg {
        fill: ${adminPortalColours.primary};
        left: 5px;
      }
    }

    td {
      margin: 0;
      padding: 0.4em 1em 0 1em;
      background-color: white;
      border-bottom: 1px solid ${adminPortalColours.greys.bordersBg};
    }

    thead {
      th {
        color: ${adminPortalColours.greys.label};
        font-family: 'AvenirBlack';
        text-align: left;
        padding: 0.2em 1em 0 1em;
      }
    }

    tfoot {
      td {
        font-family: 'AvenirBlack';
        background-color: transparent;
        border-bottom: solid 2px ${adminPortalColours.greys.dividersDisabled};
      }

      tr:hover td {
        background-color: ${adminPortalColours.greys.bg};
        cursor: default;
      }
    }
  }

  :root {
    --reach-tabs: 1;
  }

  [data-reach-dialog-overlay] {
    background-color: 'transparent';
  }

  @media print {
    html,
    body {
      font-family: 'AvenirStd';
      font-size: 12px !important;
    }

    h2,
    h3,
    h4,
    h5 {
      font-family: 'AvenirStd';
      font-size: 14px !important;
    }

    table tr {
      line-height: 25px;
    }

    a {
      font-size: 12px !important;
      color: ${adminPortalColours.black};
    }

    .no-print {
      display: none !important;
    }
  }

  @media only screen and (max-width: 1024px) {
    table {
      thead {
        font-size: 12px;

        tr {
          th {
            line-height: 15px;
            padding-bottom: 10px;
          }
        }
      }

      tr {
        line-height: 20px;

        td {
          font-size: 12px;
        }
      }
    }
  }
`
