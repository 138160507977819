type IconProps = {
  icon: string
  size?: number
  colour?: string
  viewBox?: string
  onClick?: () => void
}

const Icon = ({
  icon,
  size = 15,
  colour = 'currentColor',
  viewBox = '0 0 1048 1048',
  ...props
}: IconProps) => {
  return (
    <svg width={size} height={size} fill={colour} viewBox={viewBox} {...props}>
      <path d={icon} />
    </svg>
  )
}

export { Icon }
