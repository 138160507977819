import TagManager from "react-gtm-module";

const gtmId = "GTM-KQ84FCFN";

export const initializeTagManager = () => {
  if (
    process.env.NODE_ENV === 'production' ||
    process.env.NODE_ENV === 'test'
  ) {
    TagManager.initialize({ gtmId })
  }
};