import { AxiosError } from 'axios'
import i18n from 'i18n'
import { ErrorResponse } from 'interfaces/error'

// Error codes from backend
// See Swagger docs https://webbill-dev.brunata.dk/swagger/?urls.primaryName=Online%20Webservice#/locations/get_buildings__buildingno__locations
const errorMessageMap: { [key: string]: () => string } = {
  WB_WEBSERVICES_0000: () => i18n.t('common:errors.500_internalServerError'),
  WB_WEBSERVICES_0001: () => i18n.t('common:errors.500_internalServerError'),
  WB_WEBSERVICES_0002: () => i18n.t('common:errors.400_badRequest'),
  WB_WEBSERVICES_0003: () => i18n.t('common:errors.400_badRequest'),
  WB_WEBSERVICES_0004: () => i18n.t('common:errors.400_badRequest'),
  WB_WEBSERVICES_0005: () => i18n.t('common:errors.403_unauthorised'),
  WB_WEBSERVICES_0006: () => i18n.t('common:errors.400_badRequest'),
  WB_WEBSERVICES_0007: () => i18n.t('common:errors.404_notFound'),
  WB_WEBSERVICES_0011: () => i18n.t('common:errors.403_unauthorised'),
  WB_WEBSERVICES_0012: () => i18n.t('common:errors.WB_WEBSERVICES_0012'),
  WB_WEBSERVICES_0013: () => i18n.t('common:errors.WB_WEBSERVICES_0013'),
  WB_WEBSERVICES_0014: () => i18n.t('common:errors.WB_WEBSERVICES_0014'),
  WB_WEBSERVICES_0015: () => i18n.t('common:errors.WB_WEBSERVICES_0015'),
  WB_WEBSERVICES_0016: () => i18n.t('common:errors.WB_WEBSERVICES_0016'),
  WB_WEBSERVICES_0017: () => i18n.t('common:errors.WB_WEBSERVICES_0017'),
  WB_WEBSERVICES_0018: () => i18n.t('common:errors.WB_WEBSERVICES_0018'),
  WB_WEBSERVICES_0022: () => i18n.t('common:errors.WB_WEBSERVICES_0022'),
}

export const translateErrorMessage = (error: AxiosError<ErrorResponse>) => {
  const errorCode = error?.response?.data.errorCode
  let errorMessage = i18n.t('common:errors.500_internalServerError')

  if (errorCode) {
    const getTranslationFn = errorMessageMap[errorCode]
    const translation = getTranslationFn()

    if (translation) errorMessage = translation
  }

  return errorMessage
}
