/*  
    Utility function to lighten or darken a colour
    Example usage:
    adjustColour(adminPortalColours.primary, -20) --darken colour
    adjustColour(adminPortalColours.primary, 20) --lighten colour
*/
export const adjustColour = (colour: string, amount: number) => {
  return (
    '#' +
    colour
      .replace(/^#/, '')
      .replace(/../g, (colour) =>
        (
          '0' +
          Math.min(255, Math.max(0, parseInt(colour, 16) + amount)).toString(16)
        ).substr(-2),
      )
  )
}

export const adminPortalColours = {
  black: '#010101',
  white: '#ffffff',
  primary: '#183553', // Brunata Blue,
  primaryHover: '#022B6B', // Dark blue
  secondary: '#b30000', // Brunata Red,
  secondaryHover: '#cf0000', // Dark red
  green: '#317c72',
  yellow: '#c9b847',
  startPageBlue: '#A3BEC9',
  tableRowActive: '#cf0000',
  greys: {
    label: '#8E8E93',
    placeholdersIcons: '#BCBCBD',
    dividersDisabled: '#D2D2D2',
    bordersBg: '#E4E4E5',
    bg: '#EBECEC',
    bgHover: '#F5F5F5',
    lines: '#8e8e8e',
  },
  smileys: {
    happy: '#317C72',
    medium: '#c98947',
    grumpy: '#960000',
  },
  alarm: '#a52b22',
  utility: {
    greyBlue: '#749ba9',
    greyGreen: '#ced5be',
    blueGreen: '#537a73',
    lightBlue: '#d0e7ef',
    navyBlue: '#183553',
    pink: '#957b83',
    green: '#889c6c',
    yellow: '#e2daa1',
    red: '#B30000',
    grey: '#706F6F'
  },
}

export const residentPortalColours = {
  black: '#010101',
  white: '#ffffff',
  popup: {
    background: '#F2F2F2',
  },
  dashboard: {
    greyBlue: '#749BA9',
    greyGreen: '#ced5be',
    blueGreen: '#537a73',
    lightBlue: '#d0e7ef',
    lighBlueBg: '#EFF5F8',
    navyBlue: '#183553',
    pink: '#957b83',
    green: '#889c6c',
    darkGreen: '#307C71',
    yellow: '#e2daa1',
    lightGrey: '#f2f2f2',
    redGraph: '#a03434',
  },
  font: {
    primary: '#707070',
    secondary: '#183553',
    hover: '#183553',
    highlight: '#2F7C71',
    grey: '#636470',
    titles: '#706F6F',
    link: '#8E8E93',
  },
  icons: {
    primary: '#183553',
    secondary: '#ffffff',
    red: '#6C3048',
    backdrop: '#F5F5F5',
  },
  dropdown: {
    background: '#FAFAFA',
  },
  tips: {
    greyBlue: '#76918c',
    pink: '#b496a2',
    blue: '#528099',
  },
  arrows: {
    red: '#a32d21',
    green: '#2f7c71',
    lightGrey: '#8997A6',
  },
  select: {
    grey: '#A8CEDC33',
    shadowColor: '0 3px 10px rgb(0 0 0 / 0.2)',
  },
}

export const metersUi = {
  colours: [
    '#07304F',
    '#52809A',
    '#A52B22',
    '#317C72',
    '#C9B947',
    '#4f7184',
    '#537a73',
    '#aeaf50',
    '#528099',
    '#76918c',
    '#889c6c',
    '#7c9aa7',
    '#ced5be',
    '#c9b947',
    '#b0cdd9',
    '#e1e4d2',
    '#6c3048',
    '#d0e7ef',
    '#c2cd83',
    '#957b83',
    '#e2daa1',
    '#b496a2',
    '#d4c674',
    '#b496a2',
    '#B30000',
    '#706F6F',

    adjustColour('#07304F', 30),
    adjustColour('#52809A', 30),
    adjustColour('#A52B22', 30),
    adjustColour('#317C72', 30),
    adjustColour('#C9B947', 30),
    adjustColour('#4f7184', 30),
    adjustColour('#537a73', 30),
    adjustColour('#aeaf50', 30),
    adjustColour('#528099', 30),
    adjustColour('#76918c', 30),
    adjustColour('#889c6c', 30),
    adjustColour('#7c9aa7', 30),
    adjustColour('#ced5be', 30),
    adjustColour('#c9b947', 30),
    adjustColour('#b0cdd9', 30),
    adjustColour('#e1e4d2', 30),
    adjustColour('#6c3048', 30),
    adjustColour('#d0e7ef', 30),
    adjustColour('#c2cd83', 30),
    adjustColour('#957b83', 30),
    adjustColour('#e2daa1', 30),
    adjustColour('#b496a2', 30),
    adjustColour('#d4c674', 30),
    adjustColour('#b496a2', 30),
  ],
}
