import { createContext, useContext } from 'react'
import { useMediaQuery } from 'react-responsive'
import { mqBreakpoints } from 'styles/media-queries'

const xs = mqBreakpoints[0]
const small = mqBreakpoints[1]
const medium = mqBreakpoints[2]
const large = mqBreakpoints[3]
const xl = 1824

interface IMediaQueriesContext {
  isDesktopOrLaptop: boolean
  isBigScreen: boolean
  isTabletOrMobile: boolean
  isPortrait: boolean
  isRetina: boolean
  isMobile: boolean
  isMediumScreen: boolean
  isSmallScreen: boolean
}

const MqContext = createContext<IMediaQueriesContext | undefined>(undefined)
MqContext.displayName = 'MediaQueries'

const useMq = () => {
  const context = useContext(MqContext)
  if (context === undefined) {
    throw new Error('useMqContext must be used within a MqContext')
  }
  return context
}

const MqProvider = (props: any) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: `(min-width: ${large}px)`,
  })
  const isBigScreen = useMediaQuery({ query: `(min-width: ${xl}px)` })
  const isMediumScreen = useMediaQuery({ query: `(min-width: ${medium}px)` })
  const isTabletOrMobile = useMediaQuery({ query: `(max-width: ${small}px)` })
  const isSmallScreen = useMediaQuery({ query: `(max-width: 1000px)` })
  const isMobile = useMediaQuery({ query: `(max-width: ${xs}px)` })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

  const values = {
    isDesktopOrLaptop,
    isBigScreen,
    isMediumScreen,
    isSmallScreen,
    isTabletOrMobile,
    isPortrait,
    isRetina,
    isMobile,
  }
  return <MqContext.Provider value={values} {...props} />
}

export { MqProvider, useMq }
