import React from 'react'
import '@reach/dialog/styles.css'
import { useAuth } from 'context/auth-context'
import { UserType } from 'enums/UserType'
import { IUserProfile } from 'interfaces/user'
import Cookies from 'js-cookie'
import { initializeTagManager } from 'utils/google-tag-manager'
import TagManager from 'react-gtm-module'

const AdminPortalApp = React.lazy(() => import('1-admin-portal/admin-portal'))

const ResidentPortalApp = React.lazy(
  () => import('2-resident-portal/resident-portal'),
)

const UnauthenticatedApp = React.lazy(
  () => import('3-unauthenticated-app/unauthenticated-app'),
)

const isConsumer = (user: IUserProfile) => user.userType === UserType.CONSUMER
const isAdminUser = (user: IUserProfile) =>
  user.userType === UserType.CUSTOMER || user.userType === UserType.SUBUSER

initializeTagManager()

function App() {
  const { user } = useAuth()
  if (user != null) {
    // send custom event to GTM
    TagManager.dataLayer({
      dataLayer: {
        event: 'custom_pageview',
        user: {
          role: user.userType,
          country: user.branch.countryCode,
        },
      },
    })
    ;(window as { [key: string]: any })['apmrum'] = {
      ...((window as { [key: string]: any })['apmrum'] || {}),
      username: user.username,
    }
  }

  // Initialize Oracle APM
  ;(window as { [key: string]: any })['apmrum'] = {
    ...((window as { [key: string]: any })['apmrum'] || {}),
    serviceName: 'Browser',
    webApplication: 'reactonline',
    OracleAPMPublicDataKey: process.env.REACT_APP_ORACLE_APM_PUBLIC_DATA_KEY,
    ociDataUploadEndpoint: process.env.REACT_APP_OCI_DATA_UPLOAD_ENDPOINT,
  }

  const htmlScriptElement = document.createElement('script')
  htmlScriptElement.type = 'text/javascript'
  htmlScriptElement.async = true
  htmlScriptElement.crossOrigin = 'anonymous'
  htmlScriptElement.src = `${process.env.REACT_APP_OCI_DATA_UPLOAD_ENDPOINT}/static/jslib/apmrum.min.js`
  document.body.appendChild(htmlScriptElement)

  switch (true) {
    case user &&
      (isConsumer(user) ||
        (Cookies.get('residentStatus') && isAdminUser(user))):
      return <ResidentPortalApp />
    case user && isAdminUser(user):
      return <AdminPortalApp />
    default:
      return <UnauthenticatedApp />
  }
}

export { App }
