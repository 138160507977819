/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'

type FlexProps = {
  align?: React.CSSProperties['alignItems']
  justify?: React.CSSProperties['justifyContent']
  direction?: React.CSSProperties['flexDirection']
  wrap?: React.CSSProperties['flexWrap']
  basis?: React.CSSProperties['flexBasis']
  grow?: React.CSSProperties['flexGrow']
  shrink?: React.CSSProperties['flexShrink']
} & React.HTMLProps<HTMLDivElement>

export const Flex = React.forwardRef<HTMLDivElement, FlexProps>(
  (props, ref) => {
    const {
      direction,
      align = 'center',
      justify,
      wrap,
      basis,
      grow,
      shrink,
      ...rest
    } = props

    const styles = {
      display: 'flex',
      flexDirection: direction,
      alignItems: align,
      justifyContent: justify,
      flexWrap: wrap,
      flexBasis: basis,
      flexGrow: grow,
      flexShrink: shrink,
    }

    return <div css={styles} ref={ref} {...rest} />
  },
)

export const Spacer = styled.div({
  flex: 1,
  justifySelf: 'stretch',
  alignSelf: 'stretch',
})
