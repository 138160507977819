import CryptoJS from 'crypto-js'

const CryptoJSCachebust = CryptoJS

const key = process.env.REACT_APP_SALT_STRING || ''

export function encryptString_(word: string | undefined) {
  if (!word) return ''
  let encJson = CryptoJSCachebust.AES.encrypt(
    JSON.stringify(word),
    key,
  ).toString()
  let encData = CryptoJSCachebust.enc.Base64.stringify(
    CryptoJSCachebust.enc.Utf8.parse(encJson),
  )
  return encData
}

export function decryptString_(word: string | undefined) {
  if (!word) return ''
  let decData = CryptoJSCachebust.enc.Base64.parse(word).toString(
    CryptoJSCachebust.enc.Utf8,
  )
  let bytes = CryptoJSCachebust.AES.decrypt(decData, key).toString(
    CryptoJSCachebust.enc.Utf8,
  )
  return JSON.parse(bytes)
}
