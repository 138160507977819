/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import styled from '@emotion/styled'
import { BrunataLogo } from 'assets/icons'
import { adminPortalColours } from 'styles/colours'
import { createStyles } from 'types/emotion-styles'

const styles = createStyles({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: adminPortalColours.white,
    height: 70,
  },
  logo: {
    marginLeft: '1.75em',
    height: 50,
    cursor: 'pointer',
  },
})

export const AppHeader = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: adminPortalColours.white,
  height: 70,
  borderBottom: '1px solid',
  borderColor: adminPortalColours.greys.bordersBg,
  '@media print': {
    display: 'none',
  },
})

type AppHeaderLogoProps = {
  onClick?: () => void
}

export const AppHeaderLogo = ({ onClick }: AppHeaderLogoProps) => {
  return <BrunataLogo css={styles.logo} title="logo" onClick={onClick} />
}
