/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import styled from '@emotion/styled/macro'
import { Link as RouterLink } from 'react-router-dom'
import { keyframes } from '@emotion/react'
import { adminPortalColours } from 'styles/colours'
import React from 'react'
import { RenderFormControlProps } from './form/form-control'
import { Spacer } from './flex'
import { mq } from 'styles/media-queries'
import { useTranslation } from 'react-i18next'

const CircleSpinner: React.FC = (props) => {
  const mountTime = React.useRef(Date.now() % 1000)

  const spinnerDelay = `-${mountTime.current}ms`

  const spin = keyframes({
    to: { transform: 'rotate(360deg)' },
  })

  return (
    <div
      aria-label="loading"
      css={{
        border: `6px solid ${adminPortalColours.primary}`,
        borderRadius: '50%',
        borderRightColor: 'transparent',
        display: 'inline-block',
        width: 50,
        height: 50,
        animation: `1s linear infinite ${spin}`,
        animationDelay: `${spinnerDelay}`,
      }}
      {...props}
    />
  )
}

const Link = styled(RouterLink)({
  color: adminPortalColours.primary,
  ':hover': {
    color: adminPortalColours.primary,
    textDecoration: 'underline',
  },
})

const Box = styled.div({
  backgroundColor: 'white',
  padding: '2em',
  boxShadow: '0 0 18px -10px rgba(0, 0, 0, 0.2)',
})

const InfoBox: React.FC = (props) => {
  const { t } = useTranslation()
  return (
    <div
      css={mq({
        margin: '1.75em',
        display: ['block', 'flex'],
        padding: ['1em'],
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: adminPortalColours.primary,
        color: adminPortalColours.white,
      })}
    >
      <h3
        css={mq({
          marginBottom: ['10px', '0'],
          padding: ['0 1em', '0'],
        })}
      >
        {t('titles.note')}
      </h3>
      <Spacer />
      <div
        css={{
          fontSize: '.9em',
          whiteSpace: 'break-spaces',
          wordBreak: 'break-word',
          padding: '0 1em',
        }}
      >
        {props.children}
      </div>
      <Spacer />
    </div>
  )
}

const BoxThin = styled.div({
  backgroundColor: 'white',
  padding: '1em',
  boxShadow: '0 0 18px -10px rgba(0, 0, 0, 0.2)',
})

const Label = styled.label({
  marginBottom: '.5em',
  display: 'block',
  color: adminPortalColours.greys.label,
  whiteSpace: 'nowrap',
})

const Input = styled.input<RenderFormControlProps>(({ invalid = false }) => ({
  padding: 10,
  fontSize: 'inherit',
  borderStyle: 'solid',
  borderWidth: 2,
  flex: 1,
  borderColor: invalid
    ? adminPortalColours.secondary
    : adminPortalColours.greys.dividersDisabled,
  '&::placeholder': {
    color: adminPortalColours.greys.label,
  },
  '&:focus': {
    outlineColor: invalid
      ? adminPortalColours.secondary
      : adminPortalColours.primary,
    outlineWidth: 2,
    borderColor: adminPortalColours.greys.dividersDisabled,
  },
}))
Input.defaultProps = { autoComplete: 'off' }

const TextArea = styled.textarea<RenderFormControlProps>(
  ({ invalid = false }) => ({
    resize: 'vertical',
    padding: 10,
    maxHeight: 150,
    fontFamily: 'inherit',
    fontSize: 'inherit',
    minHeight: 100,
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: invalid
      ? adminPortalColours.secondary
      : adminPortalColours.greys.dividersDisabled,
    '&::placeholder': {
      color: adminPortalColours.greys.label,
    },
    '&:focus': {
      outlineColor: invalid
        ? adminPortalColours.secondary
        : adminPortalColours.primary,
      outlineWidth: 2,
      borderColor: adminPortalColours.greys.dividersDisabled,
    },
  }),
)

const Divider = styled.hr({
  borderColor: adminPortalColours.greys.dividersDisabled,
  backgroundColor: adminPortalColours.greys.dividersDisabled,
  borderWidth: '1px',
  borderStyle: 'solid',
  margin: '0',
})

const SectionContent = styled.div({
  margin: '1.75em',
  flexGrow: 1,
})

const HeadingPanel = styled.div({
  display: 'flex',
  height: 80,
  alignItems: 'center',
  paddingLeft: '1.75em',
  backgroundColor: adminPortalColours.white,
  width: '100%',
})

type loadingSpinnerProps = {
  title?: string | undefined
}

const FullPageSpinner = (props: loadingSpinnerProps) => {
  const { title } = props
  const optionalMessage = title ? title : ''
  return (
    <div
      css={{
        fontSize: '4em',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircleSpinner />
      <br />

      <div css={{ fontSize: '22px', marginTop: '40px' }}>{optionalMessage}</div>
    </div>
  )
}

const DotSeperator = () => {
  return (
    <span
      css={{
        fontSize: 8,
        padding: '0 1em',
        paddingBottom: 2,
        display: 'block',
      }}
    >
      {'\u2022'}
    </span>
  )
}

const AppContainer = styled.div({
  display: 'grid',
  gridTemplateColumns: 'minmax(0, 1fr)',
  gridTemplateRows: 'auto 1fr',
  height: '100%',
  backgroundColor: adminPortalColours.greys.bg,
})

export {
  Divider,
  Label,
  Input,
  TextArea,
  Box,
  BoxThin,
  Link,
  CircleSpinner,
  SectionContent,
  HeadingPanel,
  InfoBox,
  AppContainer,
  DotSeperator,
  FullPageSpinner,
}
