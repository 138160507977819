import i18next from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { isValid } from 'date-fns'

// All namespaces
export const ns = {
  common: 'common',
  systems: 'systems',
  residents: 'residents',
  userAdministration: 'userAdministration',
  residentPortal: 'residentPortal',
  mobile: 'mobile',
  webArchive: 'webArchive',
  monitoringAlarms: 'monitoringAlarms',
}

export const loadTranslations = (namespace: string) => {
  i18n.loadNamespaces(namespace)
}

const i18n = i18next.createInstance()

i18n
  .use(Backend)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    debug: false,
    load: 'languageOnly',
    defaultNS: ns.common,
    ns: [ns.common, ns.systems],
    backend: {
      loadPath: process.env.REACT_APP_TRANSLATIONS_API_ENDPOINT,
      cookieSameSite: 'strict',
    },
    detection: {
      order: ['cookie', 'navigator'],
      caches: ['cookie'],
    },
    interpolation: {
      format: (value, format, lng) => {
        if (format === 'intlDate' && isValid(new Date(value))) {
          return new Intl.DateTimeFormat(lng).format(Date.parse(value))
        }

        return value
      },

      escapeValue: false, // not needed for react as it escapes by default
    },
  })

export default i18n
