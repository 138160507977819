import { api } from './api'
import { IUserProfile } from 'interfaces/user'
import Cookies from 'js-cookie'
import axios from 'axios'
import oauth from 'axios-oauth-client'
import { decryptString_, encryptString_ } from 'utils/encrypt_cachebust'

export interface IOAuthResponse {
  not_before: number
  token_type: string
  access_token: string
  scope: string
  expires_in: number
  expires_on: number
  refresh_token: string
  refresh_token_expires_in: number
}

const getUser = async () => {
  return await api.get('/user').then((res) => {
    return res.data
  })
}

const authoriseLink = (): string => {
  const dec2hex = (dec: any) => {
    return ('0' + dec.toString(16)).substr(-2)
  }

  const generateCodeVerifier = (len: number): string => {
    var array = new Uint32Array(len / 2)
    window.crypto.getRandomValues(array)
    return Array.from(array, dec2hex).join('')
  }

  const codeChallenge = generateCodeVerifier(56)

  Cookies.set('codeChallenge', encryptString_(codeChallenge), {
    sameSite: 'Strict',
    expires: 300,
  })

  const authWrapperUrl = `${process.env.REACT_APP_BRUNATA_OAUTH_AUTHORIZE}?client_id=${process.env.REACT_APP_BRUNATA_OAUTH_CLIENTID}&redirect_uri=${process.env.REACT_APP_BRUNATA_OAUTH_REDIRECTURL}&scope=${process.env.REACT_APP_BRUNATA_OAUTH_CLIENTID}+offline_access&response_type=code&code_challenge=${codeChallenge}&code_challenge_method=plain`

  return authWrapperUrl
}

const requestCookie = async (): Promise<string> => {
  return new Promise((resolve, reject) => {
    const cv = Cookies.get('codeChallenge') || ''
    const decryptedCookie = decryptString_(cv)
    if (decryptedCookie.length === 56) {
      Cookies.remove('codeChallenge')
      return resolve(decryptedCookie)
    }
  })
}

const requestToken = async (
  code: string,
  codeVerifier: string,
): Promise<IOAuthResponse> => {
  const instance = axios.create()
  const getToken = oauth.client(instance, {
    url: process.env.REACT_APP_BRUNATA_OAUTH_TOKEN,
    client_id: process.env.REACT_APP_BRUNATA_OAUTH_CLIENTID,
    code_verifier: codeVerifier,
    code: code,
  })

  return await getToken()
}

const login = async (code: string) => {
  try {
    return requestCookie().then((explicatedCookieVal) => {
      return requestToken(code, explicatedCookieVal).then((token) => {
        Cookies.set('token', token.access_token, {
          expires: token.expires_in / 86400, // seconds to days,
          sameSite: 'strict',
          secure: true,
        })
        Cookies.set('refreshToken', token.refresh_token, {
          expires: token.refresh_token_expires_in / 1440, // minutes to days
          sameSite: 'strict',
          secure: true,
        })
        return true
      })
    })
  } catch (error) {
    throw new Error(error as any)
  }
}

const logout = async (url: string = '/') => {
  Cookies.remove('token')
  Cookies.remove('refreshToken')
  Cookies.set('LoggedOut', new Date())
  document.location.href = url
}

const getToken = async () => {
  const token = Cookies.get('token')
  if (token == null) {
    return
  }
  return { accessToken: token }
}

const getUserProfile = async (): Promise<IUserProfile | null> => {
  let user = null

  const token = await getToken()
  if (token !== undefined) {
    try {
      user = await getUser()
    } catch (error: any) {
      return null
    }
  }
  return user
}

export { getUserProfile, getUser, login, logout, getToken, authoriseLink }
