import React from 'react'
import ReactDOM from 'react-dom'
import reportWebVitals from 'reportWebVitals'
import AppProviders from 'context'
import { App } from 'App'

// Matomo Tag Manager
if (process.env.NODE_ENV === 'production') {
  var _mtm: any = _mtm || []
  _mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' })
  var d = document,
    g = d.createElement('script'),
    s = d.getElementsByTagName('script')[0]
  g.async = true
  g.src = 'https://cdn.matomo.cloud/brunataa.matomo.cloud/container_YAfG86eo.js'
  s.parentNode?.insertBefore(g, s)
}

ReactDOM.render(
  <React.StrictMode>
    <AppProviders>
      <App />
    </AppProviders>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
