/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { AppHeader, AppHeaderLogo } from 'components/app-header'
import { AppContainer, Link } from 'components/lib'
import { FallbackProps } from 'react-error-boundary'
import { adminPortalColours } from 'styles/colours'
import { createStyles } from 'types/emotion-styles'
import * as authService from 'services/auth-service'
import { ICONS } from 'styles/icon-constants'
import { Icon } from 'components/icon'
import { ApiError } from 'interfaces/error'
import { useTranslation } from 'react-i18next'
import { queryClient } from 'context'

const styles = createStyles({
  main: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  container: {
    display: 'flex',
    columnGap: '1em',
  },
  errorTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    padding: '.5em 0',
    a: {
      fontSize: 18,
    },
  },
  errorTitle: {
    color: adminPortalColours.smileys.grumpy,
    fontFamily: 'AvenirBlack',
    fontSize: '1.5em',
  },
  errorMessage: {
    color: adminPortalColours.black,
    fontSize: '1.25em',
  },
})

type FullPageErrorFallbackProps =
  | {
      error: ApiError
    }
  | Partial<FallbackProps>

export const FullPageErrorFallback = ({
  error,
}: FullPageErrorFallbackProps) => {
  const { t } = useTranslation()

  const onReset = async () => {
    await authService.logout()
    queryClient.clear()
    sessionStorage.removeItem('system')
  }

  const statusCode = error && 'statusCode' in error && error?.statusCode

  return (
    <AppContainer>
      <AppHeader>
        <AppHeaderLogo onClick={onReset} />
      </AppHeader>
      <div css={styles.main}>
        <div css={styles.container}>
          <Icon
            icon={ICONS.SMILEY_GRUMPY}
            size={140}
            colour={adminPortalColours.smileys.grumpy}
          />
          <div css={styles.errorTextContainer}>
            <h2 css={styles.errorTitle}>{t('errors.somethingWentWrong')}</h2>
            <p css={styles.errorMessage}>{error?.message}</p>
            {statusCode && (
              <p css={styles.errorMessage}>
                {t('errors.errorCode')}: {statusCode}
              </p>
            )}
            <Link to="/" onClick={onReset}>
              Go home
            </Link>
          </div>
        </div>
      </div>
    </AppContainer>
  )
}
