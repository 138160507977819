import React from 'react'
import {
  ErrorBoundary as ReactErrorBoundary,
  ErrorBoundaryProps,
} from 'react-error-boundary'

export const ErrorBoundary = (
  props: React.PropsWithChildren<ErrorBoundaryProps>,
) => {
  const { children, resetKeys = [], ...rest } = props

  return (
    <ReactErrorBoundary
      resetKeys={[window.location.href, ...resetKeys]}
      {...rest}
    >
      {children}
    </ReactErrorBoundary>
  )
}
