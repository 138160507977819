import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { ApiError, ErrorResponse } from 'interfaces/error'
import { translateErrorMessage } from 'mappers/error-messages'
import { getToken } from './auth-service'
import Cookies from 'js-cookie'

function getCurrentPath() {
  return window.location.pathname
}

let refreshToken: string | undefined = Cookies.get('refreshToken') || undefined

const formParams = new URLSearchParams()
formParams.append('refresh_token', refreshToken ?? '')

async function refreshAccessToken(): Promise<any> {
  try {
    return await api
      .post(process.env.REACT_APP_BRUNATA_OAUTH_TOKEN || '', formParams, {
        headers: {
          accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then((res: AxiosResponse<any>) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  } catch (err) {
    return Promise.reject(err)
  }
}

const api = Axios.create({
  baseURL: process.env.REACT_APP_BRUNATA_REST_ENDPOINT,
})

const apiErrorHandler = (err: AxiosError<ErrorResponse> | any) => {
  const originalRequest = err.config
  let canPass: boolean = false
  let error = {} as ApiError
  if (err?.response?.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true

    refreshAccessToken()
      .then((accessToken) => {
        Cookies.set('token', accessToken.access_token, {
          expires: accessToken.expires_in / 86400, // seconds to days
          sameSite: 'strict',
          secure: true,
        })
        Cookies.set('refreshToken', accessToken.refresh_token, {
          expires: accessToken.refresh_token_expires_in / 1440, // minutes to days
          sameSite: 'strict',
          secure: true,
        })

        api.defaults.headers.common['Authorization'] =
          'Bearer ' + accessToken.access_token
        canPass = true

        setTimeout(() => {
          document.location.href = getCurrentPath()
        }, 1000)
        return Promise.resolve(api(originalRequest))
      })
      .catch((err) => {
        error = {
          name: err?.name,
          stack: err?.stack,
          statusCode: err?.response?.status,
          statusText: err?.response?.statusText,
          message: translateErrorMessage(err),
        }
        return Promise.reject(err)
      })
  } else {
    const message = translateErrorMessage(err)
    error = {
      name: err?.name,
      stack: err?.stack,
      statusCode: err?.response?.status,
      statusText: err?.response?.statusText,
      message,
    }
  }

  if (canPass) return Promise.resolve(api(originalRequest))
  else return Promise.reject(error)
}

api.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    const token = await getToken()

    if (token !== undefined) {
      config.headers.Authorization = `Bearer ${token.accessToken}`
    }

    return config
  },
  (error) => {
    Promise.reject(error)
  },
)

api.interceptors.response.use(
  (response) => response,
  (error) => apiErrorHandler(error),
)

export { api }
