import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { AuthProvider } from './auth-context'
import { globalStyles } from 'styles/global'
import { Global } from '@emotion/react'
import { FullPageSpinner } from 'components/lib'
import { AlertProvider } from './alert-context'
import { I18nextProvider } from 'react-i18next'
import i18n from 'i18n'
import { HelmetProvider } from 'react-helmet-async'
import { MqProvider } from './media-queries-context'
import { ErrorBoundary, FullPageErrorFallback } from 'components/errors'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      useErrorBoundary: true,
      retry: false,
    },
  },
})

const AppProviders: React.FC = ({ children }) => {
  return (
    <ErrorBoundary FallbackComponent={FullPageErrorFallback}>
      <HelmetProvider>
        <QueryClientProvider client={queryClient}>
          <MqProvider>
            <Router>
              <React.Suspense fallback={<FullPageSpinner />}>
                <I18nextProvider i18n={i18n}>
                  <Global styles={globalStyles} />
                  <AlertProvider>
                    <AuthProvider>{children}</AuthProvider>
                  </AlertProvider>
                </I18nextProvider>
              </React.Suspense>
            </Router>
          </MqProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </HelmetProvider>
    </ErrorBoundary>
  )
}

export default AppProviders
