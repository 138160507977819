import React, { createContext, useContext, useState } from 'react'
import { useAsync } from '../utils/hooks'
import * as authService from 'services/auth-service'
import { useTranslation } from 'react-i18next'
import { FullPageSpinner } from 'components/lib'
import { IUserProfile } from 'interfaces/user'
import { useQueryClient } from 'react-query'
import { FullPageErrorFallback } from 'components/errors'
import Cookies from 'js-cookie'
interface IAuthContext {
  user: IUserProfile
  authoriseLink: () => string
  login: any
  logout: () => void
  refetchUser: () => void
  isAuthenticating: boolean
}

const AuthContext = createContext<IAuthContext | undefined>(undefined)
AuthContext.displayName = 'AuthContext'

const useAuth = () => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider')
  }
  return context
}

const AuthProvider: React.FC = (props) => {
  const { i18n } = useTranslation()
  const queryClient = useQueryClient()
  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(true)

  const {
    data: user,
    error,
    isLoading,
    isIdle,
    isError,
    isSuccess,
    run,
    setData,
    status,
  } = useAsync<IUserProfile>()

  const authoriseLink = () => {
    return authService.authoriseLink()
  }

  const login = async (code: string) => {
    const logged = await authService.login(code)
    if (logged) {
      setIsAuthenticating(false)
    }
  }

  const logout = async () => {
    queryClient.clear()
    Cookies.remove('residentStatus')
    sessionStorage.removeItem('system')
    Cookies.remove('token')
    Cookies.remove('BRUN_SESSID_WBDEV') // TODO: This doesn't work as intended
    setData(null)

    await authService.logout()
  }

  React.useEffect(() => {
    run(authService.getUserProfile())
  }, [run])

  React.useEffect(() => {
    if (user?.language) {
      i18n.changeLanguage(user.language)
    }
  }, [i18n, user?.language])

  const refetchUser = () => {
    run(authService.getUserProfile())
  }

  if (isLoading || isIdle) {
    return <FullPageSpinner />
  }

  if (isError) {
    return <FullPageErrorFallback error={error} />
  }
  if (isSuccess) {
    const value = {
      user,
      login,
      logout,
      refetchUser,
      authoriseLink,
      isAuthenticating,
    }

    return <AuthContext.Provider value={value} {...props} />
  }

  throw new Error(`Unhandled status: ${status}`)
}

export { AuthProvider, useAuth }
