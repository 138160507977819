import React, { createContext, useCallback, useContext, useState } from 'react'

export type AlertType = 'success' | 'error'

type Alert = {
  message: React.ReactNode
  type: AlertType
  timeout?: boolean
  ms?: number
}

interface IAlertContext {
  alert: Alert
  addAlert: (params: Alert) => void
  removeAlert: () => void
}

const AlertContext = createContext<IAlertContext | undefined>(undefined)

export const useAlert = () => {
  const context = useContext(AlertContext)

  if (context === undefined) {
    throw new Error('useAlert must be used within a AlertProvider')
  }

  return context
}

export const AlertProvider: React.FC = ({ children }) => {
  const [alert, setAlert] = useState({} as Alert)
  const removeAlert = () => setAlert({} as Alert)
  const addAlert = (params: Alert) => {
    setAlert({} as Alert)
    setAlert(params)
  }

  const value = {
    alert,
    addAlert: useCallback((params: Alert) => {
      addAlert(params)
    }, []),
    removeAlert: useCallback(() => removeAlert(), []),
  }

  return <AlertContext.Provider value={value}>{children}</AlertContext.Provider>
}
