import { System } from '1-admin-portal/context/system-context'
import { ApiError } from 'interfaces/error'
import React, {
  MutableRefObject,
  RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { useQueryClient } from 'react-query'
import { useLocation } from 'react-router-dom'

const useDetectOutsideClick = (
  el: MutableRefObject<any>,
  initialState: any,
) => {
  const [isActive, setIsActive] = useState(initialState)

  const pageClickEvent = useCallback(
    (e: MouseEvent) => {
      if (el.current !== null && !el.current.contains(e.target)) {
        setIsActive(!isActive)
      }
    },
    [el, isActive],
  )

  useEffect(() => {
    if (isActive) {
      window.addEventListener('click', pageClickEvent)
    }

    return () => {
      window.removeEventListener('click', pageClickEvent)
    }
  }, [el, isActive, pageClickEvent])

  return [isActive, setIsActive]
}

const useBreadcrumbs = () => {
  const { pathname } = useLocation()

  const pathnames = React.useMemo(
    () => pathname.split('/').filter((x) => x),
    [pathname],
  )

  const firstPage = pathnames[0]
  const lastPage = pathnames[pathnames.length - 1]

  return { pathnames, firstPage, lastPage }
}

function useSafeDispatch(dispatch: any) {
  const mounted = useRef(false)

  useLayoutEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  return React.useCallback(
    (...args) => (mounted.current ? dispatch(...args) : void 0),
    [dispatch],
  )
}

type InitialState<T> = {
  status: string
  data: T
}

const defaultInitialState: InitialState<any> = { status: 'idle', data: null }

type UseAsyncReturn<T> = {
  isIdle: boolean
  isLoading: boolean
  isError: boolean
  isSuccess: boolean
  setData: (data: T | null) => T
  setError: (error: ApiError) => ApiError
  error: ApiError
  status: string
  data: T
  run: (promise: Promise<T | null>) => T | null
  reset: () => any
}

function useAsync<T>(initialState?: InitialState<T>): UseAsyncReturn<T> {
  const initialStateRef = React.useRef({
    ...defaultInitialState,
    ...initialState,
  })
  const [{ status, data, error }, setState] = React.useReducer(
    (s: any, a: any) => ({ ...s, ...a }),
    initialStateRef.current,
  )

  const safeSetState = useSafeDispatch(setState)

  const setData = React.useCallback(
    (data) => safeSetState({ data, status: 'resolved' }),
    [safeSetState],
  )
  const setError = React.useCallback(
    (error) => safeSetState({ error, status: 'rejected' }),
    [safeSetState],
  )
  const reset = React.useCallback(
    () => safeSetState(initialStateRef.current),
    [safeSetState],
  )

  const run = React.useCallback(
    (promise) => {
      if (!promise || !promise.then) {
        throw new Error(
          `The argument passed to useAsync().run must be a promise. Maybe a function that's passed isn't returning anything?`,
        )
      }
      safeSetState({ status: 'pending' })
      return promise.then(
        (data: any) => {
          setData(data)
          return data
        },
        (error: ApiError) => {
          setError(error)
          return Promise.reject(error)
        },
      )
    },
    [safeSetState, setData, setError],
  )

  return {
    // using the same names that react-query uses for convenience
    isIdle: status === 'idle',
    isLoading: status === 'pending',
    isError: status === 'rejected',
    isSuccess: status === 'resolved',

    setData,
    setError,
    error,
    status,
    data,
    run,
    reset,
  }
}

const useUrlQuery = () => {
  return new URLSearchParams(useLocation().search)
}

export function useIsMutating() {
  const queryClient = useQueryClient()
  const [pendingMutations, setPendingMutations] = React.useState(0)

  React.useEffect(
    () =>
      queryClient.getMutationCache().subscribe(() => {
        setPendingMutations(
          queryClient
            .getMutationCache()
            .getAll()
            .filter((mutation) => mutation.state.status === 'loading').length,
        )
      }),
    [queryClient],
  )

  return pendingMutations
}

export type UseStepperReturn = {
  step: number
  setStep: React.Dispatch<React.SetStateAction<number>>
  handleNext: () => void
  handlePrevious: () => void
  isFirstStep: boolean
  isLastStep: boolean
}

const useStepper = (totalSteps: number): UseStepperReturn => {
  const [step, setStep] = useState<number>(0)

  const handleNext = () => {
    setStep((currStep) => currStep + 1)
  }

  const handlePrevious = () => {
    setStep((currStep) => currStep - 1)
  }

  const isFirstStep = step === 0
  const isLastStep = step === totalSteps - 1

  return { step, setStep, handleNext, handlePrevious, isLastStep, isFirstStep }
}

export type UseModalReturn<T = unknown> = {
  isOpen: boolean
  handleModalOpen: (data?: T) => void
  handleModalClose: () => void
  data: T | undefined
}

const useModal = <T>(): UseModalReturn<T> => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [data, setData] = useState<T>()

  const handleModalOpen = (data?: T) => {
    setIsOpen(true)
    setData(data)
  }

  const handleModalClose = () => {
    setIsOpen(false)
  }

  return { isOpen, handleModalOpen, handleModalClose, data }
}

const useOnClickOutside = (
  ref: RefObject<HTMLDivElement>,
  handler: (event: MouseEvent) => void,
) => {
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (!ref.current || ref.current.contains(event.target as any)) {
        return
      }
      handler(event)
    }
    document.addEventListener('mousedown', listener)
    return () => {
      document.removeEventListener('mousedown', listener)
    }
  }, [ref, handler])
}

const useSessionBuildingName = (): System => {
  const ref = useRef<string | null>(null)

  ref.current = sessionStorage.getItem('system') || '{}'
  const sessionSystem = JSON.parse(ref.current)

  return sessionSystem
}

function useDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500)

    return () => {
      clearTimeout(timer)
    }
  }, [value, delay])

  return debouncedValue
}

export {
  useAsync,
  useBreadcrumbs,
  useDetectOutsideClick,
  useUrlQuery,
  useStepper,
  useModal,
  useOnClickOutside,
  useSessionBuildingName,
  useDebounce,
}
