/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { Icon } from 'components/icon'
import { Link } from 'components/lib'
import { ApiError } from 'interfaces/error'
import React from 'react'
import { FallbackProps } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import { adminPortalColours } from 'styles/colours'
import { ICONS } from 'styles/icon-constants'
import { createStyles } from 'types/emotion-styles'

const styles = createStyles({
  main: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  container: {
    display: 'flex',
    columnGap: '1em',
  },
  errorTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '.5em',
    justifyContent: 'space-evenly',
    padding: '.5em 0',
    h2: {
      color: adminPortalColours.smileys.grumpy,
      fontFamily: 'AvenirBlack',
      fontSize: '1.5em',
    },
    a: {
      textDecoration: 'none',
      fontSize: 18,
    },
  },
  errorMessage: {
    fontSize: '1.25em',
    fontcolor: adminPortalColours.greys.label,
  },
})

type ErrorMessageProps =
  | {
      error: ApiError
    }
  | FallbackProps

export const ErrorMessage = ({ error }: ErrorMessageProps) => {
  const { t } = useTranslation()

  const statusCode = error && 'statusCode' in error && error?.statusCode

  return (
    <div role="alert" css={styles.main}>
      <div css={styles.container}>
        <Icon
          icon={ICONS.SMILEY_GRUMPY}
          size={140}
          colour={adminPortalColours.smileys.grumpy}
        />
        <div css={styles.errorTextContainer}>
          <h2>{t('errors.somethingWentWrong')}</h2>
          <p css={styles.errorMessage}>{error.message}</p>
          {statusCode && (
            <React.Fragment>
              <p css={styles.errorMessage}>
                {t('errors.errorCode')}: {statusCode}
              </p>
            </React.Fragment>
          )}
          {statusCode === 404 && (
            <React.Fragment>
              <br />
              <p css={{ fontSize: 18 }}>{t('errors.linksYouCanTry')}</p>
              <Link to="/choose-building">
                {t('systems:titles.chooseSystem')}
              </Link>
              <Link to="/profile">{t('titles.userProfile')}</Link>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  )
}
